/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 42번째 로그에서는 인사이트 출판사 송우일 님을 모시고 IT 출판사, 전자책, IT 도서 출간 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/"
  }, "개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/394"
  }, "테라폼 삽질 공유회 | Festa!")), "\n"), "\n", React.createElement(_components.h2, null, "손에 잡히는 10분 정규 표현식 /  IT 도서 출판사"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.insightbook.co.kr/"
  }, "도서출판 인사이트 – 프로그래밍, IT 관련 서적을 발간합니다.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=196607460"
  }, "알라딘: 손에 잡히는 10분 정규 표현식")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=4342103"
  }, "알라딘: 손에 잡히는 정규표현식")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=175977462"
  }, "알라딘: 함께 자라기 - 애자일로 가는 길")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=193890896"
  }, "알라딘: 실리콘밸리의 팀장들 - 까칠한 인재마저 사로잡은 그들의 지독한 솔직함")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pragprog.com/"
  }, "The Pragmatic Bookshelf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pragprog.com/book/elixir16/programming-elixir-1-6"
  }, "Programming Elixir 1.6 | The Pragmatic Bookshelf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pragprog.com/book/crystal/programming-crystal"
  }, "Programming Crystal | The Pragmatic Bookshelf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sites.google.com/site/anpshare/anpedia/altlang"
  }, "대안언어축제 - anpshare")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://langdev.org/"
  }, "LangDev")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.packtpub.com/"
  }, "Packt | Programming Books, eBooks & Videos for Developers")), "\n"), "\n", React.createElement(_components.h2, null, "알고리즘"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=21089176"
  }, "알라딘: 알고리즘 문제 해결 전략 - 전2권 - 프로그래밍 대회에서 배우는")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.acmicpc.net/"
  }, "Baekjoon Online Judge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=190239393"
  }, "알라딘: 알고리즘 트레이닝 : 프로그래밍 대회 입문 가이드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=115116545&start=slayer"
  }, "알라딘: 코딩 인터뷰 완전 분석 - 187가지 프로그래밍 문제와 해법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/75187284?scode=032&OzSrank=1"
  }, "프로그래밍 면접, 이렇게 준비한다 - YES24")), "\n"), "\n", React.createElement(_components.h2, null, "전자책"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oreilly.com/?"
  }, "O'Reilly Media - Technology and Business Training")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://ebook.insightbook.co.kr/"
  }, "도서출판 인사이트 전자책")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.inflearn.com/"
  }, "인프런 - 배우고 나누고 성장하세요 | 온라인 강의 플랫폼")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/EPUB"
  }, "EPUB - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/23/"
  }, "stdout_23.log: 만우절, 일본의 새 연호, 북스캔 w/ ecleya | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/seungjoon.choi/posts/10218462071678624"
  }, "최승준 - 이북 대여 서비스에 관한 상상... (친구 공개)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/All-new-Kindle-Oasis-now-with-adjustable-warm-light/dp/B07L5GDTYY/ref=sr_1_1?keywords=kindle+oasis&qid=1565516893&s=gateway&sr=8-1"
  }, "Amazon.com: All-new Kindle Oasis - Now with adjustable warm light - 8 GB, Graphite (International Version): Gateway")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://book.interpark.com/display/displayBiscuit.do?_method=listGuideBiscuitNew&tabNum=1"
  }, "비스킷 - 싸니까 믿으니까 인터파크도서")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=19577173"
  }, "알라딘: 리버싱 핵심 원리 - 악성 코드 분석가의 리버싱 이야기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=38267313"
  }, "알라딘: 아마존, 세상의 모든 것을 팝니다 - 아마존과 제프 베조스의 모든 것")), "\n"), "\n", React.createElement(_components.h2, null, "IT 서적 출판하기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://issuu.com/iamhwchoi/docs/____it_________________4_"
  }, "주유의 IT 책 쓰기 불바다 강론 : 빠르게 포기하거나, 진짜로 저자가 되거나 by 최현우 - issuu")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/WegraLee"
  }, "WegraLee (Wegra Lee)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lambdanote.com/collections/frontpage/products/ruby-ruby"
  }, "(일본어) Ruby로 만드는 Ruby(전자책)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ascii.jp/elem/000/001/230/1230449/"
  }, "(일본어) ASCII.jp：Ruby로 배우는 Ruby(잡지 연재)")), "\n"), "\n", React.createElement(_components.h2, null, "중국 / 그 외"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%84%A0%EC%A0%84%EC%8B%9C"
  }, "선전시 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/international/article/2018060836101"
  }, "中 선전시내 모든 택시 연내 전기차로 바꾼다 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tencent.com/zh-cn/index.html"
  }, "Tencent 腾讯")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=3401563"
  }, "알라딘: 프로그래밍 그루비")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=7168225"
  }, "알라딘: 프로그래밍 클로저")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=11329212"
  }, "알라딘: 프로그램 디자인, 어떻게 할 것인가")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Mindstorms-Children-Computers-Powerful-Ideas/dp/0465046746/ref=sr_1_3?keywords=mindstorm&qid=1565571836&s=books&sr=1-3"
  }, "Amazon.com: Mindstorms: Children, Computers, And Powerful Ideas")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=27620362"
  }, "알라딘: 패턴 랭귀지 - 도시 건축 시공")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=8757187"
  }, "알라딘: 똑바로 일하라 - 성과는 일벌레를 좋아하지 않는다")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=6882369"
  }, "알라딘: 미래를 만든 Geeks")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=470771"
  }, "알라딘: 멀티미디어 - 바그너에서 가상현실까지")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
